<template>
    <v-app class="app-container">
        <v-main>
            <v-container fluid>
                <Menu />

                <MyAppBar />

                <v-slide-x-transition mode="out-in">
                    <!-- <router-view></router-view> -->
                    <router-view
                        :key="$route.fullPath"
                        :route_name="$route.name"
                    />
                </v-slide-x-transition>

       
        <Snackbars />
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import Menu from '../../components/Global/Overlay/Menu/OverlayMenuMenu'
import MyAppBar from './../../components/Global/Overlay/AppBar'
import RightBox from '../../components/Global/RightBox/TheRightBox'
import Snackbars from '../../components/Global/Snackbars/TheSnackbars.vue'
import { mapMutations } from 'vuex'
export default {
    data: () => ({
        //
    }),
    methods: {
        ...mapMutations(['clearSearchText']),
    },
    watch: {
        $route: {
            handler: function (to, from) {
                if (from && from.name !== to.name) {
                    this.clearSearchText()
                }
            },
            deep: true,
            immediate: true,
        },
    },
    components: {
        Menu,
        MyAppBar,
        RightBox,
        Snackbars,
    },
}
</script>

<style lang="scss"></style>
