<template>
    <v-container class="px-0">
        <v-card class="details__main">
            <v-tabs
                show-arrows
                height="60"
                class="details__tabs"
                centered
                v-model="active"
                icons-and-text
            >
                <v-tab href="#information">
                    {{ $t('global:information') }}
                    <v-icon size="18">mdi-book-information-variant</v-icon>
                </v-tab>

                <v-tab-item id="information">
                    <!-- @slot Use this slot to edit informations content-->
                    <slot name="information"></slot>
                </v-tab-item>
                <!-- @slot Use this slot to add extra tabs content-->
                 <v-tab
                    v-for="item in items"
                    :key="item.id"
                    :href="`#${item.id}`"
                >
                    {{ item.name }}
                    <v-badge
                        v-if="item.id === 'parts' && getServicrPartsBadge"
                        color="orange darken-1"
                        class="badge--details"
                        offset-x="-10"
                        offset-y="-12"
                        content=""
                    >
                    </v-badge>
                    <v-icon size="18">{{ item.icon }}</v-icon>
                </v-tab>
                <slot name="tabItems"></slot>
            </v-tabs>
        </v-card>
    </v-container>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
/**
 * Tabs main views
 * @displayName Tabs main views
 */
export default {
    props: {
        /**
         * Props to hidden Tasks tabs
         */
        tasksTabs: {
            type: Boolean,
            default: true,
        },
        /**
         * Props to hidden Alerts tabs
         */
        alertsTabs: {
            type: Boolean,
            default: true,
        },
        /**
         * Props to hidden Tasks tabs
         */
        tasksTabs: {
            type: Boolean,
            default: true,
        },
        /**
         * Props to active tabs
         */
        items: Array,
        /**
         * Props to active tabs
         */
        activeTab: {
            type: String,
            default: 'information',
        },
    },
    i18nOptions: { namespaces: ['global'] },
    data() {
        return {
            active: this.activeTab,
        }
    },


}
</script>
