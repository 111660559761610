import Vue from 'vue'

import App from './App.vue'
import router from './router'
import store from './store'
import vuetify from './plugins/vuetify'
import qs from 'qs'
import axios from 'axios'
import vueAxios from 'vue-axios'
import Rest from './plugins/Rest.js'
import money from 'v-money'
import TheMoneyField from './components/Global/MoneyField/TheMoneyField.vue'
// import myMixin from './plugins/myMixin';
import TheSeparator from './components/Global/Separator/TheSeparator.vue'
import TheTable from './components/Global/Table/TableTable.vue'
import DetailsTabsHeader from './components/Global/Details/DetailsTabsHeader.vue'

import Main from './components/Global/Details/Main.vue'

import TheRegistryHeader from './components/Global/Table/TableHeader.vue'
import TheModal from './components/Global/Modal/TheModal.vue'
import TheConfirmModal from './components/Global/ConfirmModal/TheConfirmModal.vue'
import TheSnackbars from './components/Global/Snackbars/TheSnackbars'
import TheDoubleLine from './components/Global/Table/TableDoubleLine'
import TheSummaryLine from './components/Global/SummaryLine/TheSummaryLine'
import * as VueGoogleMaps from 'vue2-google-maps'
import './registerServiceWorker'

// === === GOOGLE MAPS === === //
Vue.use(VueGoogleMaps, {
    load: {
        key: process.env.VUE_APP_GOOGLE_MAP_API,
    },
})

// MOMENT JS
import moment from 'moment'
Vue.prototype.$moment = moment
moment.locale('pl')

// LODASH
import get from 'lodash.get'
Vue.prototype.$get = get

// AXIOS SETUP
axios.defaults.paramsSerializer = params => qs.stringify(params)
axios.defaults.baseURL = `${process.env.VUE_APP_SERVER}/api/v2`
axios.defaults.withCredentials = true
axios.defaults.mode = 'no-cors'
axios.defaults.credentials = 'same-origin'
Vue.use(vueAxios, axios)
// Snackbars
Vue.component('Snackbars', TheSnackbars)
// Separator
Vue.component('Separator', TheSeparator)
// Table
Vue.component('Table', TheTable)
Vue.component('RegistryHeader', TheRegistryHeader)

// Details
Vue.component('DetailsTabsHeader', DetailsTabsHeader)
Vue.component('Main', Main)
//NewServiceModal
Vue.component('Modal', TheModal)

// Confirme NewServiceModal
Vue.component('ConfirmModal', TheConfirmModal)

// DoubleLine
Vue.component('DoubleLine', TheDoubleLine)
//MoneyField
Vue.component('MoneyField', TheMoneyField)
// SummaryLine
Vue.component('SummaryLine', TheSummaryLine)

// Vue.mixin(myMixin);

Vue.use(money, { precision: 4 })

Vue.prototype.$qs = qs
Vue.prototype.Rest = Rest

Vue.config.productionTip = false

// I18N SETUP
import i18n from './plugins/i18next'

new Vue({
    i18n: i18n,
    router,
    store,
    vuetify,
    render: h => h(App),
}).$mount('#app')
