<template>
  <div class="rightBox__cards">
    <v-alert
      v-for="(item, index) in items"
      :key="index"
      border="left"
      colored-border
      class="rightBox__card py-2"
      :color="$get(item, 'priority.backgroundColor', 'grey')"
      elevation="2"
      width="310"
    >
      <p class="mt-2 mb-1 rightBox__card__description">
        {{ item.description }}
      </p>
      <span class="rightBox__card__line" />
      <span class="rightBox__card__date"><b>{{ $t('global:term') }}:</b>
        {{ item.alertTime }}
        {{ $moment(item.alertDate).format(`DD-MM-YYYY`) }}</span>
      <div>
        <span>
          <v-tooltip bottom>
            <template #activator="{ on }">
              <v-icon
                size="15"
                color="primary"
                class="mr-1"
                v-on="on"
              >mdi-account</v-icon>
              <span
                class="cursor-pointer rightBox__card__summary"
                v-on="on"
              >{{ $get(item, 'from.name', '') }}
                {{ $get(item, 'from.lastname', '') }}</span>
            </template>
            <span>{{ $t('tasks:created') }}</span>
          </v-tooltip>
        </span>
        <span class="rightBox__card__span">
          <v-tooltip bottom>
            <template #activator="{ on }">
              <v-icon
                size="15"
                color="primary"
                class="mr-1"
                v-on="on"
              >mdi-calendar-month</v-icon>
              <span
                class="cursor-pointer rightBox__card__summary"
                v-on="on"
              >{{ $moment(item.createdAt).format(`HH:mm`) }}
                {{
                  $moment(item.createdAt).format(`DD-MM-YYYY`)
                }}</span>
            </template>
            <span>{{ $t('tasks:createdDate') }}</span>
          </v-tooltip>
        </span>
      </div>
      <v-tooltip
        v-if="!item.seen"
        bottom
      >
        <template #activator="{ on }">
          <v-avatar
            v-if="!item.seen"
            color="#ffcb00"
            class="rightBox__card__new"
            size="28"
            v-on="on"
            @click="setSeen(item)"
          >
            <span class="white--text">N</span>
          </v-avatar>
        </template>
        <span>{{ $t('alerts:unread') }}</span>
      </v-tooltip>
      <v-tooltip bottom>
        <template #activator="{ on }">
          <v-btn
            small
            :to="'/alerts'"
            icon
            class="rightBox__card__arrow"
            v-on="on"
          >
            <v-icon
              size="18"
              color="white"
            >
              mdi-arrow-right-thick
            </v-icon>
          </v-btn>
        </template>
        <span>{{ $t('alerts:go') }}</span>
      </v-tooltip>
    </v-alert>
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
export default {
    props: {
        items: {
            type: Array,
        },
    },
    methods: {
        ...mapActions(['updateAlert']),
        async setSeen(item) {
            let success = await this.updateAlert({
                id: item._id,
                body: { seen: true },
            })
            if (success) item.seen = true
            this.$emit('showBadge')
        },
    },
    computed: {
        ...mapGetters(['getProfileDetails']),
    },
}
</script>
