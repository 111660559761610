<template>
  <div class="doubleLine">
    <span>{{ firtstLine }}</span>
    <span
      v-if="typeof secondLine == 'string'"
      class="grey--text"
    >{{
      secondLine
    }}</span>
    <span
      v-else
      class="grey--text"
    >
      <span
        v-for="item in secondLine"
        :key="item._id"
        class="doubleLine__array"
      >{{ item.name }} {{ item.lastname }},
      </span>
    </span>
  </div>
</template>
<script>
export default {
    props: {
        firtstLine: {
            // type: String,
        },
        secondLine: {
            // type: String,
        },
    },
}
</script>
