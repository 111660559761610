<template>
  <div class="d-flex">
    <div class="summaryLine grey--text">
      <slot name="leftText" />
      <span
        class="summaryLine__leftSite"
        :style="`padding-bottom:${paddingBottom}px`"
      >{{ firtstLine }}
      </span>
      <div
        :style="`padding-bottom:${paddingBottom}px; width:${widthRightSite}px; `"
        class="summaryLine__rightSite"
      >
        <slot name="rightText" />
        <span v-if="text">{{ text }}</span>
        <span v-if="number"><money
          v-bind="money"
          class="price--device"
          readonly
          :value="parseInt(number)"
        /></span>
        <slot
          v-else
          name="checkbox"
        />
      </div>
    </div>
  </div>
</template>
<script>
export default {
    props: {
        firtstLine: {
            type: String,
        },
        secondLine: {
            type: String,
        },
        paddingBottom: {
            default: '15',
        },
        checkboxValue: {
            default: false,
        },
        isCheckbox: {
            default: false,
        },
        readonly: {
            default: false,
        },
        text: {},
        number: {},
        widthRightSite: {
            default: 120,
        },
        disabled: {
            default: false,
        },
    },
    data() {
        return {
            val: this.checkboxValue,
            money: {
                decimal: ',',
                thousands: '.',
                prefix: '',
                suffix: ' PLN',
                precision: 2,
                masked: false,
            },
        }
    },
    methods: {
        updateValue(value) {
            this.$emit('change', value)
        },
    },
}
</script>
<style lang="sass" scoped>
.price--device
    color: #9e9e9e !important
.margin-10
    margin-top: -10px !important
</style>
