import { render, staticRenderFns } from "./TheAvatar.vue?vue&type=template&id=9885c8d8&scoped=true&"
import script from "./TheAvatar.vue?vue&type=script&lang=js&"
export * from "./TheAvatar.vue?vue&type=script&lang=js&"
import style0 from "./TheAvatar.vue?vue&type=style&index=0&id=9885c8d8&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "9885c8d8",
  null
  
)

export default component.exports

/* vuetify-loader */
import installComponents from "!../../../../node_modules/vuetify-loader/lib/runtime/installComponents.js"
import { VAvatar } from 'vuetify/lib/components/VAvatar';
import { VCard } from 'vuetify/lib/components/VCard';
import { VDivider } from 'vuetify/lib/components/VDivider';
import { VMenu } from 'vuetify/lib/components/VMenu';
import { VTooltip } from 'vuetify/lib/components/VTooltip';
installComponents(component, {VAvatar,VCard,VDivider,VMenu,VTooltip})
