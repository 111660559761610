<template>
    <v-navigation-drawer
        v-click-outside="onClickOutside"
        :mini-variant="getNav.mini"
        mini-variant-width="65"
        absolute
        dark
        app
        touchless
        :permanent="true"
        src="@/assets/img/global/menu-background.jpg"
    >
        <div class="navigation__bg">
            <router-link tag="div" to="/dashboard">
                <div :class="`navigation__logo${getNav.mini ? '__small' : ''}`">
                    <img
                        v-if="!getNav.mini"
                        src="@/assets/img/global/repairs/e_r_logo_dark.png"
                        alt="Logo Entelli"
                    />
                    <img
                        v-if="getNav.mini"
                        class="pa-2"
                        src="@/assets/img/global/logos_e.png"
                        alt="Logo"
                    />
                </div>
            </router-link>
            <!-- === PERSON === -->
            <router-link tag="div" to="/profile">
                <div
                    class="navigation__avatar"
                    :class="!getNav.mini ? 'pl-5' : 'pl-3'"
                >
                    <v-avatar
                        color="secondary"
                        :size="!getNav.mini ? '50' : '40'"
                    >
                        <img
                            v-if="getProfilePicture.url"
                            :src="getProfilePicture.url"
                            :alt="getProfileDetails.name"
                        />
                        <span v-else class="white--text"
                            >{{ $get(getProfileDetails, 'name[0]')
                            }}{{ $get(getProfileDetails, 'lastName[0]') }}</span
                        >
                    </v-avatar>

                    <transition name="fade">
                        <div v-if="!getNav.mini">
                            <p class="pa-0 ma-0 mt-1 white--text">
                                {{ $get(getProfileDetails, 'name') }}
                                {{ $get(getProfileDetails, 'lastName') }}
                            </p>
                            <div class="pa-0 ma-0 mr-5">
                                <span>{{
                                    $get(getProfileDetails, 'contact.name')
                                }}</span>
                            </div>

                            <span class="pa-0 ma-0">{{
                                getProfileDetails.position
                            }}</span>
                        </div>
                    </transition>
                </div>
            </router-link>

            <v-stepper
                v-model="getNav.stepper"
                class="navigation__list elevation-0"
            >
                <v-stepper-items>
                    <v-stepper-content step="1" class="pa-0 pa-0">
                        <v-list
                            nav
                            dark
                            flat
                            class="elevation-0 active-menu"
                            data-cy="list-main"
                        >
                            <v-list-item
                                v-for="(item, index) in stepOne(zeroDeepMenu)"
                                :key="index"
                                :to="item.link"
                                @click="goToStepper(item.step)"
                            >
                                <v-list-item-icon>
                                    <v-icon>mdi-{{ item.icon }}</v-icon>
                                </v-list-item-icon>
                                <v-list-item-title class="font-weight-light">{{
                                    item.name
                                }}</v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </v-stepper-content>
                    <v-stepper-content step="3" class="pa-0 pt-0 pr-0">
                        <div
                            class="pa-2 pl-4 d-flex align-center cursor-pointer"
                            @click="getNav.stepper = 1"
                        >
                            <v-icon color="secondary">mdi-arrow-left</v-icon>
                            <span
                                v-if="getNav.mini == false"
                                class="title ml-2 font-weight-light"
                                >{{ $t('global:personal') }}</span
                            >
                        </div>
                        <v-list
                            nav
                            dark
                            flat
                            class="elevation-0 active-menu"
                            data-cy="list-personal"
                        >
                            <v-list-item
                                v-for="(item, index) in personal"
                                :key="index"
                                :to="item.link"
                            >
                                <v-list-item-icon>
                                    <v-icon>mdi-{{ item.icon }}</v-icon>
                                </v-list-item-icon>
                                <v-list-item-title class="font-weight-light">{{
                                    item.name
                                }}</v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </v-stepper-content>
                    <v-stepper-content step="2" class="pa-0 pt-0 pr-0">
                        <div
                            class="pa-2 pl-4 d-flex align-center cursor-pointer"
                            @click="getNav.stepper = 1"
                        >
                            <v-icon color="secondary">mdi-arrow-left</v-icon>
                            <span
                                v-if="getNav.mini == false"
                                class="title ml-2 font-weight-light"
                                >{{ $t('global:registries') }}</span
                            >
                        </div>
                        <v-list
                            nav
                            dark
                            flat
                            class="elevation-0 active-menu"
                            data-cy="list-registries"
                        >
                            <v-list-item
                                v-for="item in registries"
                                :key="item._id"
                                :to="`/${item.url}`"
                                class="my-0"
                            >
                                <v-list-item-icon>
                                    <v-icon>{{ item.icon }}</v-icon>
                                </v-list-item-icon>
                                <v-list-item-title class="font-weight-light">{{
                                    item.name
                                }}</v-list-item-title>
                            </v-list-item>
                        </v-list>
                    </v-stepper-content>
                    <v-stepper-content step="4" class="pa-0 pt-0 pr-0">
                        <div
                            class="pa-2 pl-4 d-flex align-center cursor-pointer"
                            @click="getNav.stepper = 1"
                        >
                            <v-icon color="secondary">mdi-arrow-left</v-icon>
                            <span
                                v-if="getNav.mini == false"
                                class="title ml-2 font-weight-light"
                                >{{ $t('global:admin') }}</span
                            >
                        </div>
                        <v-list
                            nav
                            dark
                            flat
                            class="elevation-0 active-menu"
                            data-cy="list-admin"
                        >
<!--                            <v-list-item-->
<!--                                v-for="(item, index) in admin"-->
<!--                                :key="index"-->
<!--                                :to="`/${item.link}`"-->
<!--                                class="my-0"-->
<!--                            >-->
<!--                                <v-list-item-icon>-->
<!--                                    <v-icon>mdi-{{ item.icon }}</v-icon>-->
<!--                                </v-list-item-icon>-->
<!--                                <v-list-item-title class="font-weight-light">{{-->
<!--                                    item.name-->
<!--                                }}</v-list-item-title>-->
<!--                            </v-list-item>-->
                        </v-list>
                    </v-stepper-content>
                    <v-stepper-content step="5" class="pa-0 pt-0 pr-0">
                        <div
                            class="pa-2 pl-4 d-flex align-center cursor-pointer"
                            @click="getNav.stepper = 1"
                        >
                            <v-icon color="secondary">mdi-arrow-left</v-icon>
                            <span
                                v-if="getNav.mini == false"
                                class="title ml-2 font-weight-light"
                                >{{ $t('global:superAdmin') }}</span
                            >
                        </div>
                        <v-list nav dark flat class="elevation-0">
<!--                            <v-list-item-->
<!--                                v-for="(item, index) in superAdmin"-->
<!--                                :key="index"-->
<!--                                :to="`/${item.link}`"-->
<!--                                class="my-0"-->
<!--                            >-->
<!--                                <v-list-item-icon>-->
<!--                                    <v-icon>mdi-{{ item.icon }}</v-icon>-->
<!--                                </v-list-item-icon>-->
<!--                                <v-list-item-title class="font-weight-light">{{-->
<!--                                    item.name-->
<!--                                }}</v-list-item-title>-->
<!--                            </v-list-item>-->
                        </v-list>
                    </v-stepper-content>
                </v-stepper-items>
            </v-stepper>
            <div class="navigation__burger">
                <v-app-bar-nav-icon @click="getNav.mini = !getNav.mini" />
            </div>
            <TodayDate />
        </div>
    </v-navigation-drawer>
</template>

<script>
import TodayDate from './OverlayMenuData'
import { mapGetters } from 'vuex'
export default {
    i18nOptions: { namespaces: ['global'] },
    components: {
        TodayDate,
    },
    data() {
        return {
            nav: {
                stepper: 1,
            },
            windowWidth: window.innerWidth,
            txt: '',
            active: false,
            items: [],
            personal: [],
            registries: [
                {
                    name: this.$t('global:services-order'),
                    icon: 'mdi-wrench',
                    link: '/service-orders',
                },
            ],
            zeroDeepMenu: [
                {
                    name: this.$t('global:profile'),
                    icon: 'account-search',
                    link: '/profile',
                    step: '1',
                    active: true,
                },
                {
                    name: this.$t('global:services-order'),
                    icon: 'wrench',
                    link: '/service-orders',
                    step: '1',
                    active: true,
                },
                {
                    name: this.$t('NotifyAdminSetup:Administracja'),
                    icon: 'shield-crown',
                    link: '/admin-setup',
                    value: 'Admin',
                  
                },
            ],
        }
    },
    computed: {
        ...mapGetters(['getNav', 'getProfileDetails', 'getProfilePicture']),
    },
    beforeMount() {
        this.checkWindowSize()
    },
    methods: {
        stepOne(items) {
            let arr = []
            items.forEach(item => {
                if (this.getProfileDetails?.isSuperAdmin == true) {
                    item.active = true
                } else if (
                    this.getProfileDetails.isAdmin == true &&
                    item.value == 'Admin'
                ) {
                    item.active = true
                }
                if (item.active == true) {
                    arr.push(item)
                }
            })

            return arr
        },
        goToStepper(step) {
            this.getNav.stepper = step
        },
        checkWindowSize() {
            this.windowWidth < 1200
                ? (this.getNav.mini = true)
                : (this.getNav.mini = false)
        },
        onClickOutside() {
            if (this.windowWidth < 1200) {
                this.getNav.mini = true
            }
        },
        goToSite(link) {
            this.$router.push(link)
        },
    },
}
</script>
<style scoped>
.navigation__logo img {
    width: 100%;
}
</style>
