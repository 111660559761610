import i18n from './../../plugins/i18next'
import Rest from './../../plugins/Rest'
import validation from './../../plugins/validation'
const state = {
    registries: [],
    modal: {
        fields: {
            oldPass: '',
            password: null,
            passwordAgain: null,
            generated: null,
            phoneNumber: null,
        },
        errors: {
            stepOne: {
                validation: true,
                fields: {
                    oldPass: '',
                    password: '',
                    passwordAgain: '',
                },
            },
        },
        pictures: {
            formData: '',
            canvas: '',
        },
    },
    req: {
        stepOne: ['oldPass', 'password', 'passwordAgain'],
    },
    details: {
        mail: '',
        name: '',
        lastname: '',
        login: '',
        phone: '',
        language: '',
        position: '',
    },
    request: {
        select: 'data',
    },
    picture: {
        blob: '',
        url: '',
    },
    session: null,
    timer: null,
    sessionId: null,
}
const getters = {
    // getLanguages: state => state.languages,
    getProfile: state => state.modal.fields,
    getPictures: state => state.modal.pictures,
    getProfileDetails: state => state.details,
    getProfileError: state => state.modal.errors,
    getProfileRegistries: state => state.registries,
    getProfilePicture: state => state.picture,
    getProfileSession: state => state.session,
    getTimer: state => state.timer,
    getSessionId: state => state.sessionId,
    // checkUserPermission: state => op => {
    //     if (state.details.isAdmin || state.details.isSuperAdmin) return true
    //     else if (
    //         state.details.role &&
    //         state.details.role.registries.some(registry =>
    //             registry.operations.includes(op)
    //         )
    //     )
    //         return true
    //     else return false
    // },
}

const actions = {
    async changeLanguage({ state, commit }, payload) {
        try {
            await new Rest('PUT', `user/${payload.id}`).setBody(payload).send()

            window.location.reload(true)
            commit('setSnackbars', { type: 'success' })
            return true
        } catch (err) {
            console.error(err.response.data)
            commit('setSnackbars', { type: 'error' })
        }
    },
    async sendPictures({ state, commit }, payload) {
        try {
            let response = await new Rest('PUT', `/user/picture/${payload.id}`)
                .setBody(state.modal.pictures.formData)
                .send()

            commit('setPicture', response.data.pictureBinary?.data)
        } catch (err) {
            console.error(err)
        }
    },
    async updatePassword({ state, commit }, payload) {
        validation.frontVal(state.modal, state.req)
        if (state.modal.errors.stepOne.validation == true) {
            try {
                await new Rest('PUT', `user/${payload.id}`)
                    .setBody(state.modal.fields)
                    .send()
                commit('setSnackbars', { type: 'success' })
                return true
            } catch (err) {
                console.error(err)
                if (err.response.data.errors.password) {
                    commit('setSnackbars', {
                        type: 'error',
                        text: i18n.t(
                            `profile:${err.response.data.errors.password}`
                        ),
                    })
                } else {
                    commit('setSnackbars', { type: 'error' })
                }
            }
        }
    },
    async updatePersonal({ state, commit }, payload) {
        try {
            let response = await new Rest('PUT', `user/personal/${payload.id}`)
                .setBody(state.modal.fields)
                .populate(['contact'])
                .send()

            commit('setUserData', response.data)
            commit('setSnackbars', { type: 'success' })
            return true
        } catch (err) {
            console.error(err)
        }
    },
}

const mutations = {
    setUserData(state, payload) {
        state.details = payload
    },
    setLanguage(state, payload) {
        state.details.language = payload
    },

    clearProfileModal(state, payload) {
        for (const i in state.modal.fields) {
            state.modal.fields[i] = ''
        }
    },
    clearProfileErrors(state) {
        for (const step in state.modal.errors) {
            for (const key in state.modal.errors[step]) {
                state.modal.errors[step][key] = ''
                state.modal.errors[step].validation = true
            }
        }
    },
    generatePassword(state) {
        const chars =
            '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz'

        let code = ''
        for (let i = 0; i < 12; i++) {
            let index = Math.floor(Math.random() * chars.length)
            code += chars[index]
        }
        state.modal.fields.password = code
        state.modal.fields.passwordAgain = code
        state.modal.fields.generated = `${i18n.t(
            'profile:generatedPassword'
        )} ${code}`
    },
    setPictures(state, payload) {
        state.modal.pictures = payload
    },
    setPicture(state, payload) {
        if (payload?.data) {
            let imageBuffer = new Uint8Array(payload?.data)
            let image = new Blob([imageBuffer], { type: 'image/jpeg' })
            state.picture = { blob: image, url: URL.createObjectURL(image) }
        } else {
            state.picture = { url: '' }
        }
    },
    clearProfile(state, payload) {
        state.picture = {}
    },

    setUserSession(state, payload) {
        state.session = payload.time
        state.sessionId = payload.id
    },
    decrementSessionTime(state, payload) {
        state.session -= 1
    },
    setTimer(state, payload) {
        state.timer = payload
    },
}

export default {
    state,
    getters,
    actions,
    mutations,
}
