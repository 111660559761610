<template>
  <v-container class="p-relative">
    <v-row>
      <v-col
        cols="12"
        class="py-0"
      >
        <TableContacts
          :items="contacts"
          :headers="headers"
          :default-fields="getContactsBoxSearchFields.searchFields"
          @search="setSearch"
        >
          <Columns
            slot="columns"
            :items="contacts"
          />
        </TableContacts>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import Columns from './RightBoxContactsColumns'
import TableContacts from './RightBoxContactsTable'
import store from './../../../../store/index'
import { mapGetters, mapMutations, mapActions } from 'vuex'

export default {
    components: { Columns, TableContacts },
    props: ['contacts'],
    i18nOptions: { namespaces: ['contacts', 'global'] },
    computed: {
        ...mapGetters(['getContactsBoxSearchFields']),
    },

    methods: {
        ...mapActions(['fetchContactsBoxTable']),
        ...mapMutations(['setContactsBoxTable', 'getContactsBoxTable']),

        setSearch(search) {
            this.setContactsBoxTable({ search, page: 1 })
            this.fetchContactsBoxTable()
        },
    },

    data: () => ({
        open: false,
        headers: [
            {
                text: 'Nazwa',
                value: 'name',
            },
        ],
    }),
}
</script>
