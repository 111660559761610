<template>
  <div class="price">
    <label>{{ label }}</label>
    <money
      class="input-effect"
      :value="value"
      :readonly="readonly"
      :disabled="disabled"
      v-bind="money"
      @input="updateValue($event)"
    />
    <span class="focus-border" />
  </div>
</template>

<script>
export default {
    props: {
        value: {
            default: 0,
        },
        label: {
            default: '',
        },
        readonly: {
            default: false,
        },

        disabled: {
            default: false,
        },
        precision:{
            default:2
        }
    },
    data() {
        return {
            money: {
                decimal: ',',
                thousands: '.',
                prefix: '',
                suffix: ' PLN',
                precision: this.precision,
                masked: false,
            },
        }
    },
    methods: {
        updateValue(value) {
            this.$emit('input', value)
        },
    },
}
</script>
