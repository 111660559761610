<template>
    <!-- === === TOP TOOLBAR === === -->
    <div>
        <v-app-bar app clipped-right color="white" class="header-bar">
            <v-spacer />
            <v-btn text :style="`color:${color}`" @click="refresh()">
                {{ counter }}
            </v-btn>

            <v-btn
                text
                icon
                color="grey lighten-1"
                router
                to="/dashboard"
                data-cy="home"
                @click="getNav.stepper = 1"
            >
                <v-icon>mdi-home</v-icon>
            </v-btn>

            <v-btn
                text
                icon
                color="grey lighten-1"
                data-cy="logout"
                @click="logout"
            >
                <v-icon>mdi-power</v-icon>
            </v-btn>
            <Modal
                :open="openQrCode"
                :title="$t('global:QrScannerModal')"
                :height="'540'"
                :width="'480'"
                @close="closeModal"
            />
            <ConfirmModal
                :open-confirm="openSessionModal"
                @close-confirm="closeConfirmModal"
            >
                <div slot="main">
                    <div
                        class="
                            pa-5
                            confirmModal
                            d-flex
                            flex-column
                            align-center
                        "
                    >
                        <v-progress-circular
                            class="my-3"
                            rotate="270"
                            size="140"
                            :value="(getProfileSession / 15) * 5"
                            color="secondary"
                            width="8"
                        >
                            <span class="session--time">{{ counter }}</span>
                        </v-progress-circular>

                        <v-card-title class="d-flex justify-center pa-2">
                            {{ $t('global:session') }}
                        </v-card-title>

                        <v-card-text class="d-flex justify-center pa-2">
                            {{ $t('global:sessionInformation') }}
                        </v-card-text>
                    </div>
                </div>
                <div slot="buttons">
                    <div class="d-flex justify-center">
                        <v-btn
                            color="rgb(117, 117, 117)"
                            width="110"
                            height="40"
                            class="white--text mx-2"
                            @click="closeConfirmModal()"
                        >
                            {{ $t('global:close') }}
                        </v-btn>
                        <v-btn
                            width="140"
                            height="40"
                            color="#445d75"
                            class="white--text mx-2"
                            @click="refresh()"
                        >
                            {{ $t('global:extension') }}
                        </v-btn>
                    </div>
                </div>
            </ConfirmModal>
        </v-app-bar>
    </div>
</template>
<script>
import { mapGetters, mapMutations } from 'vuex'

import Rest from '../../../plugins/Rest'
export default {
    components: {},
    data: () => {
        return {
            time: 0,
            color: 'black',
            openSessionModal: false,
            openQrCode: false,
        }
    },
    computed: {
        ...mapGetters([
            'getNav',
            'getProfileSession',
            'getTimer',
            'getLogoutModal',
            'getProfileRegistries',
        ]),
        counter() {
            let time = new Date(this.getProfileSession * 1000)
                .toISOString()
                .slice(14, -5)
            return time
        },
    },
    methods: {
        ...mapMutations([
            'clearProfile',
            'decrementSessionTime',
            'setUserSession',
            'setTimer',
        ]),
        closeModal() {
            this.openQrCode = false
        },
        closeConfirmModal() {
            this.openSessionModal = false
        },
        logout() {
            this.getNav.stepper = 1
            try {
                this.$router.push('login')
            } catch (error) {
                // ZAKOMENTOWANE
                // if (error.response.status === 401) this.$router.push('login');
            }

            this.clearProfile()
        },
        timer() {
            let interval = 1000
            let expected = Date.now() + interval
            let id

            function step() {
                if (this.getProfileSession == 300) {
                    this.color = 'red'
                    this.openSessionModal = true
                }
                let dt = Date.now() - expected
                expected += interval
                this.decrementSessionTime()
                if (this.getProfileSession <= 0) {
                    this.getLogoutModal.open = true
                    return this.logout()
                }
                id = setTimeout(
                    () => step.apply(this),
                    Math.max(0, interval - dt)
                )
                this.setTimer(id)
            }
            id = setTimeout(() => step.apply(this), interval)
            this.setTimer(id)
        },
        async refresh() {
            this.color = 'black'
            this.openSessionModal = false
            let response = await new Rest('GET', 'token/refresh').send()
            this.setUserSession({ time: response.data.session.time })
        },
        async closeDetect() {
            // window.onbeforeunload = e => {
            //     e.preventDefault()
            //     return false
            // }
            // window.onunload = async () => {
            //     await this.logout()
            // }
        },
    },

    mounted() {
        if (this.getTimer) clearTimeout(this.getTimer)
        this.timer()
    },
    beforeMount() {
        this.closeDetect()
    },
}
</script>
<style lang="sass">
.session--time
    font-size: 30px
#task__draggable-box
    position: absolute
    z-index: 10
    text-align: center
    visibility: hidden
</style>
