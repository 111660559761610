
<template>
  <v-snackbar
    v-model="getSnackbars.snackbar"
    :timeout="2500"
    :color="getSnackbars.type"
    right
    top
    class="snackbars"
  >
    <v-icon
      v-if="getSnackbars.type === 'error'"
      color="white"
    >
      mdi-close-circle
    </v-icon>
    <v-icon
      v-if="getSnackbars.type === 'success'"
      color="white"
    >
      mdi-check-circle
    </v-icon>
    <v-icon
      v-if="getSnackbars.type === 'warning'"
      color="white"
    >
      mdi-alert-circle
    </v-icon>
    <div>
      <span
        v-if="getSnackbars.type === 'error'"
        data-cy="snackbarError"
        class="snackbars__text"
      >{{
        !getSnackbars.text ? $t('global:error') : getSnackbars.text
      }}</span>
      <span
        v-if="getSnackbars.type === 'success'"
        data-cy="snackbarSuccess"
        class="snackbars__text"
      >{{
        !getSnackbars.text
          ? $t('global:success')
          : getSnackbars.text
      }}</span>
      <span
        v-if="getSnackbars.type === 'warning'"
        data-cy="snackbarWarning"
        class="snackbars__text"
      >{{
        !getSnackbars.text
          ? $t('global:warning')
          : getSnackbars.text
      }}</span>
    </div>
    <v-btn
      class="snackbars__btn"
      dark
      icon
      text
      @click="closeSnackbars()"
    >
      <v-icon size="15">
        mdi-close
      </v-icon>
    </v-btn>
  </v-snackbar>
</template>

<script>
import { mapMutations, mapGetters } from 'vuex'
export default {
    props: ['text', 'timeout'],
    i18nOptions: { namespaces: ['global'] },
    data: () => ({}),
    methods: {
        ...mapMutations(['showSnackbars', 'closeSnackbars']),
    },
    computed: {
        ...mapGetters(['getSnackbars']),
    },
}
</script>