var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',{staticClass:"central"},[_c('v-row',{staticClass:"flex-column"},[_c('v-card',{staticClass:"central__card elevation-3"},[_c('div',{staticClass:"d-flex justify-end p-relative"},[(_vm.sortForMe)?_c('v-col',{staticClass:"py-0 central__card__sortForMe",attrs:{"cols":"2","lg":"3","xl":"2","md":"4","sm":"8"}},[_c('div',{staticClass:"d-flex mt-5 mt-0-media"},[_c('v-btn',{staticClass:"textGrey--text py-2",attrs:{"small":"","elevation":"0","color":_vm.forMe ? 'gray' : 'white'},on:{"click":function($event){return _vm.$emit('alertsTo')}}},[_c('v-icon',{attrs:{"color":"secondary","size":"20"}},[_vm._v("mdi-account-arrow-left")]),_vm._v(_vm._s(_vm.$t('tasks:toMe')))],1),_c('v-btn',{staticClass:"textGrey--text py-2",attrs:{"small":"","elevation":"0","color":!_vm.forMe ? 'gray' : 'white'},on:{"click":function($event){return _vm.$emit('alertsFrom')}}},[_c('v-icon',{staticClass:"mr-2",attrs:{"color":"secondary","size":"20"}},[_vm._v("mdi-account-arrow-right")]),_vm._v(" "+_vm._s(_vm.$t('tasks:forOthers')))],1)],1)]):_vm._e(),(!_vm.isSearchDisabled)?_c('v-col',{attrs:{"cols":"10","lg":"3","xl":"2","md":"4","sm":"4"}},[_c('v-text-field',{ref:"search",staticClass:"central__card__search",attrs:{"data-cy":"search","label":_vm.$t('global:search'),"single-line":"","hide-details":""},on:{"keyup":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.$emit('search', _vm.getSearch.text)}},scopedSlots:_vm._u([{key:"append-outer",fn:function(){return [_c('v-icon',{attrs:{"color":"secondary"},on:{"click":function($event){return _vm.$emit('search', _vm.getSearch.text)}}},[_vm._v("mdi-magnify")])]},proxy:true},{key:"append",fn:function(){return [(_vm.searchOption)?_c('v-menu',{attrs:{"min-width":"200","close-on-content-click":false},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var onMenu = ref.on;
return [_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var onTooltip = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","small":"","color":"#ccc"}},Object.assign({}, onMenu,
                                                    onTooltip)),[_c('v-icon',{attrs:{"size":"15"}},[_vm._v("mdi-cog")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('global:editSearch')))])])]}}],null,false,2009586093)},[_c('v-list',{staticClass:"pa-3"},[_c('p',{staticClass:"pa-2 ma-0"},[_vm._v(" "+_vm._s(_vm.$t('global:searchByField'))+" ")]),_vm._l((_vm.headers),function(item,index){return _c('div',{key:index,staticClass:"px-3 ma-0"},[(_vm.showItem(item))?_c('v-list-item-title',[_c('v-checkbox',{attrs:{"disabled":_vm.requiredFields.includes(
                                                        item.value
                                                    )
                                                        ? true
                                                        : false,"value":item.value,"label":item.text,"color":"secondary","hide-details":""},on:{"change":function($event){return _vm.$emit(
                                                        'search-fields',
                                                        _vm.selected
                                                    )}},model:{value:(_vm.selected),callback:function ($$v) {_vm.selected=$$v},expression:"selected"}})],1):_vm._e()],1)})],2)],1):_vm._e(),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                                                    var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"icon":"","small":"","color":"#ccc"},on:{"click":function($event){return _vm.refresh()}}},{ on: on }),[_c('v-icon',{attrs:{"size":"15"}},[_vm._v("mdi-autorenew")])],1)]}}],null,false,4252317492)},[_c('span',[_vm._v(_vm._s(_vm.$t('global:editSearch')))])])]},proxy:true}],null,false,3733165551),model:{value:(_vm.getSearch.text),callback:function ($$v) {_vm.$set(_vm.getSearch, "text", $$v)},expression:"getSearch.text"}})],1):_vm._e()],1),_c('v-data-table',{staticClass:"central__table mt-4",attrs:{"headers":_vm.headers,"items":!_vm.loading ? _vm.items : [],"height":'calc(100vh - 362px)',"fixed-header":"","search":_vm.search,"items-per-page":11,"hide-default-footer":"","options":_vm.options,"item-key":"_id","loading":_vm.loading},on:{"update:options":function($event){_vm.options=$event}},scopedSlots:_vm._u([(!_vm.loading)?{key:"body",fn:function(ref){
                                                    var isExpanded = ref.isExpanded;
                                                    var expand = ref.expand;
return [_vm._t("columns",null,{"isExpanded":isExpanded,"expand":expand})]}}:null],null,true)}),_c('v-pagination',{attrs:{"circle":"","length":_vm.length,"total-visible":5},model:{value:(_vm.pg),callback:function ($$v) {_vm.pg=$$v},expression:"pg"}}),[_vm._t("buttons")]],2)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }