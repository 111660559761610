<template>
  <!--
    Close confirme modala 
    @event close-confirm 
    -->
  <v-dialog
    v-model="openConfirm"
    width="500"
    @click:outside="$emit('close-confirm')"
  >
    <v-card class="pa-5">
      <!-- @slot Use this slot to add confirme modal content-->
      <slot name="main" />
      <!-- @slot Use this slot to add confirme modal buttons-->
      <slot name="buttons" />
    </v-card>
  </v-dialog>
</template>

<script>
import Vue from 'vue'
/**
 * Confirme NewServiceModal
 * @displayName Confirme NewServiceModal
 */
export default {
    props: {
        /**
         * Open Confirme modal
         */
        openConfirm: {
            type: Boolean,
            default: false,
        },
    },
}
</script>
