<template>
    <v-container class="central">
        <v-row class="flex-column">
            <v-card class="central__card elevation-3">
                <div class="d-flex justify-end p-relative">
                    <v-col
                        v-if="sortForMe"
                        cols="2"
                        lg="3"
                        xl="2"
                        md="4"
                        sm="8"
                        class="py-0 central__card__sortForMe"
                    >
                        <div class="d-flex mt-5 mt-0-media">
                            <v-btn
                                small
                                elevation="0"
                                @click="$emit('alertsTo')"
                                :color="forMe ? 'gray' : 'white'"
                                class="textGrey--text py-2"
                            >
                                <v-icon color="secondary" size="20"
                                    >mdi-account-arrow-left</v-icon
                                >{{ $t('tasks:toMe') }}</v-btn
                            >
                            <v-btn
                                small
                                @click="$emit('alertsFrom')"
                                elevation="0"
                                :color="!forMe ? 'gray' : 'white'"
                                class="textGrey--text py-2"
                            >
                                <v-icon color="secondary" class="mr-2" size="20"
                                    >mdi-account-arrow-right</v-icon
                                >
                                {{ $t('tasks:forOthers') }}</v-btn
                            >
                        </div>
                    </v-col>

                    <v-col
                        v-if="!isSearchDisabled"
                        cols="10"
                        lg="3"
                        xl="2"
                        md="4"
                        sm="4"
                    >
                        <v-text-field
                            data-cy="search"
                            :label="$t('global:search')"
                            v-model="getSearch.text"
                            ref="search"
                            single-line
                            class="central__card__search"
                            hide-details
                            @keyup.enter="$emit('search', getSearch.text)"
                        >
                            <!-- @input="$emit('search', getSearch.text)" -->

                            <template v-slot:append-outer>
                                <v-icon
                                    @click="$emit('search', getSearch.text)"
                                    color="secondary"
                                    >mdi-magnify</v-icon
                                >
                            </template>
                            <template v-slot:append>
                                <v-menu
                                    v-if="searchOption"
                                    min-width="200"
                                    :close-on-content-click="false"
                                >
                                    <template #activator="{ on: onMenu }">
                                        <v-tooltip bottom>
                                            <template
                                                #activator="{ on: onTooltip }"
                                            >
                                                <v-btn
                                                    v-on="{
                                                        ...onMenu,
                                                        ...onTooltip,
                                                    }"
                                                    icon
                                                    small
                                                    color="#ccc"
                                                >
                                                    <v-icon size="15"
                                                        >mdi-cog</v-icon
                                                    >
                                                </v-btn>
                                            </template>
                                            <span>{{
                                                $t('global:editSearch')
                                            }}</span>
                                        </v-tooltip>
                                    </template>

                                    <v-list class="pa-3">
                                        <p class="pa-2 ma-0">
                                            {{ $t('global:searchByField') }}
                                        </p>
                                        <div
                                            v-for="(item, index) in headers"
                                            :key="index"
                                            class="px-3 ma-0"
                                        >
                                            <!-- {{ item }} -->
                                            <v-list-item-title
                                                v-if="showItem(item)"
                                            >
                                                <v-checkbox
                                                    :disabled="
                                                        requiredFields.includes(
                                                            item.value
                                                        )
                                                            ? true
                                                            : false
                                                    "
                                                    @change="
                                                        $emit(
                                                            'search-fields',
                                                            selected
                                                        )
                                                    "
                                                    v-model="selected"
                                                    :value="item.value"
                                                    :label="item.text"
                                                    color="secondary"
                                                    hide-details
                                                ></v-checkbox
                                            ></v-list-item-title>
                                        </div>
                                    </v-list>
                                </v-menu>
                                <v-tooltip bottom>
                                    <template v-slot:activator="{ on }">
                                        <v-btn
                                            v-on="{ on }"
                                            icon
                                            @click="refresh()"
                                            small
                                            color="#ccc"
                                        >
                                            <v-icon size="15"
                                                >mdi-autorenew</v-icon
                                            >
                                        </v-btn>
                                    </template>
                                    <span>{{ $t('global:editSearch') }}</span>
                                </v-tooltip>
                            </template>
                        </v-text-field>
                    </v-col>
                </div>

                <v-data-table
                    :headers="headers"
                    :items="!loading ? items : []"
                    :height="'calc(100vh - 362px)'"
                    fixed-header
                    :search="search"
                    :items-per-page="11"
                    class="central__table mt-4"
                    hide-default-footer
                    :options.sync="options"
                    item-key="_id"
                    :loading="loading"
                >
                    <template
                        v-if="!loading"
                        v-slot:body="{ isExpanded, expand }"
                    >
                        <!-- @slot Use this slot to place the table records -->
                        <slot
                            name="columns"
                            :isExpanded="isExpanded"
                            :expand="expand"
                        ></slot>
                    </template>
                </v-data-table>
                <v-pagination
                    circle
                    :length="length"
                    :total-visible="5"
                    v-model="pg"
                ></v-pagination>
                <template>
                    <slot name="buttons"></slot>
                </template>
            </v-card>
        </v-row>
    </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
/**
 * Central Table
 * @displayName Central Table
 */
export default {
    i18nOptions: { namespaces: ['global'] },
    props: {
        isSearchDisabled: {
            type: Boolean,
            default: false,
        },
        loading: {
            type: Boolean,
            default: false,
        },
        items: {
            /**
             * Items in Table
             */
            type: Array,
            required: false,
        },
        /**
         * Headers in Table
         */
        headers: {
            type: Array,
            required: true,
        },
        /**
         * Table Length
         */
        length: {
            type: Number,
            required: true,
        },
        /**
         * Table Search Options
         */
        searchOption: {
            default: true,
        },
        /**
         * Table Height
         */
        height: {
            default: 'calc(100vh - 362px)',
        },
        page: { type: Number, required: true },
        /**
         * Table default search fields
         */
        defaultFields: { type: Array, required: true },
        sortForMe: {
            type: Boolean,
        },
        forMe: {
            type: Boolean,
        },
        title: {
            type: String,
        },
    },

    data() {
        return {
            radioSelect: '1',
            search: '',
            options: {},
            selected: [],
            requiredFields: this.defaultFields,
            selected: this.defaultFields,
        }
    },

    computed: {
        ...mapGetters(['getProfileDetails', 'getSearch']),
        pg: {
            get: function () {
                return this.page
            },
            set: function (val) {
                this.$emit('change-page', val)
            },
        },
    },

    methods: {
        ...mapActions(['fetchAlertsTable']),
        setFocus() {
            this.$refs.search.focus()
        },

        async refresh() {
            await this.$emit('clear-table')
            this.selected = this.defaultFields
        },
        showItem(item) {
            if (item.showSearch == true) return true
        },
    },

    watch: {
        options: {
            handler() {
                let sortBy = this.options.sortBy[0]
                let sortDesc = this.options.sortDesc[0]
                let sort = sortBy
                if (sortDesc) sort = `-${sortBy}`
                if (sort == undefined) sort = ''
                this.$emit('sort', sort)
            },
            deep: true,
        },
    },
}
</script>

<style lang="scss" scoped>
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (-webkit-min-device-pixel-ratio: 1) {
    .central__card__search {
        margin-top: 0px;
        margin-bottom: 0px;
        padding: 0px;
        margin-right: 40px;
    }

    .v-application .mt-4 {
        margin-top: 0px !important;
    }
    .title-mobile-container {
        margin-left: 14px;
        justify-content: center;
        align-items: center;
    }
    .title-mobile {
        position: absolute;
        font-family: 'Roboto', sans-serif;
        font-size: 1.4rem;
        font-weight: 300;
        margin-top: 6px;
        letter-spacing: 1.2px;
        color: #808080;
    }

    .central__card {
        min-height: 79vh;
        margin-bottom: -4px;
    }
}

@media only screen and (min-device-width: 1025px) and (max-device-width: 1440px) {
    .central__card__search {
        margin-top: 0px;
        margin-bottom: 0px;
        padding: 0px;
        margin-right: 40px;
    }

    .central__card {
        min-height: 90vh;
        margin-bottom: -3px;
    }

    .v-application .mt-4 {
        margin-top: 0px !important;
    }

    .title-mobile {
        line-height: 30px;
        margin-left: 20px;
        font-size: 1.1rem;
        letter-spacing: 1.2px;
        text-transform: uppercase;
    }
}
</style>
