import Vue from 'vue';
import i18next from 'i18next';
import VueI18Next from '@panter/vue-i18next';
import store from '../store';
import moment from 'moment';
import crypto from 'crypto-js';

Vue.use(VueI18Next);

i18next.init({
  lng: 'pl',
  resources: {},
});

export const loadLanguage = async () => {
  try {
    const secret = crypto.HmacSHA512(`/api/v2/language${moment().format("DD-MM-YYYY mm")}`,process.env.VUE_APP_LANGUAGE_SECRET).toString();
    const res = await fetch(`${process.env.VUE_APP_TRANSLATION_SERVER}/api/v2/language`,{
        headers:{
            'authorization':secret
        }
    })
    const resources = await res.json();
    if (res.status === 200) {
      store.commit('setLanguageState', false);
      for (let lang in resources) {
        if (resources[lang] && Object.keys(resources[lang]).length > 0) {
          for (let namespace in resources[lang]) {
            i18next.addResourceBundle(
              lang,
              namespace,
              resources[lang][namespace]
            );
          }
        } else {
          i18next.addResourceBundle(lang, 'global', {});
        }
      }
    }

    for (let lang in resources) {
      if (Object.keys(i18next.getDataByLanguage(lang)).length <= 0) {
        loadLanguage();
      }
    }
    store.commit('setLanguageState', true);
  } catch (err) {
    console.error(err);
  }
};

loadLanguage();

const i18n = new VueI18Next(i18next);

export default i18n;
