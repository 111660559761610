import Vue from 'vue'
import Vuex from 'vuex'
import store from '../index'
import Rest from '../../plugins/Rest'
Vue.use(Vuex)

const state = {
    navigation: {
        mini: false,
        stepper: 1,
    },
    snackbars: {
        snackbar: false,
        type: '',
        text: '',
    },
    logoutModal: {
        open: false,
    },
    url: '',
    search: {
        text: '',
    },
    applicationLink: '123',
}
const getters = {
    getNav: state => state.navigation,
    getSnackbars: state => state.snackbars,
    getUrl: state => state.url,
    getSearch: state => state.search,
    getLogoutModal: state => state.logoutModal,
    getApplicationLink: state => state.applicationLink,
}
const mutations = {
    setSnackbars(state, payload) {
        state.snackbars.snackbar = true
        state.snackbars.type = payload.type
        state.snackbars.text = payload.text
    },
    closeSnackbars(state) {
        state.snackbars.snackbar = false
    },
    assignUrl(state, payload) {
        state.url = payload
    },
    clearSearchText(state) {
        state.search.text = ''
    },
    setApplicationLink(state, payload) {
        state.applicationLink = payload.applicationLink
    },
}
const actions = {
    async fetchApplicationLink({ commit, dispatch }, payload) {
        
        try {
            let response = await new Rest('GET', 'globalAccessAPI/applicationLink').send()

            if(response.status === 200) {
                const applicationLink = response.data.applicationLink;
               
                commit('setApplicationLink', {
                    applicationLink,
                })
            }


            // let { 0: response } = await Promise.all([serviceReq.send()])
            // commit('setServicePartsDetails', response.data.parts)
            // if (payload.badge) {
            //     commit('setServicePartsBadge', response.data.parts)
            // }
        } catch (error) {
       
            commit('setApplicationLink', {
                applicationLink: 'https://platform2.entelli.pl/secured/logo/client_logo.png',
            })
        
        }


        
    },
}

export default {
    state,
    getters,
    mutations,
    actions,
}
