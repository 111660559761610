<template>
    <v-navigation-drawer
        v-if="$route.name !== 'dashboard'"
        :mini-variant.sync="mini"
        right
        src="@/assets/img/global/menu-background.jpg"
        app
        clipped
        touchless
        permanent
        mini-variant-width="50"
        class="rightBox"
        width="50"
    >
        <!-- width="384" -->
        <!-- {{ getTasksPersonal.items }} -->
        <v-tabs
            v-model="model"
            slider-color="secondary"
            slider-size="3"
            vertical
            class="rightBox__tabs"
            icons-and-text
        >
            <v-tooltip left>
                <template #activator="{ on }">
                    <v-tab class="rightBox__panel" href="#tasks" v-on="on">
                        <!-- <v-badge
                            v-if="
                                getTasksPersonal.activeTasks &&
                                getTasksPersonal.activeTasks.length > 0
                            "
                            class="badgeRB"
                            color="#ffcb00"
                            offset-x="-4"
                            offset-y="-16"
                            overlap
                        /> -->
                        <v-icon color="white"> mdi-format-list-checks </v-icon>
                    </v-tab>
                </template>
                <span>{{ $t('global:tasks') }} </span>
            </v-tooltip>

            <!-- <v-tooltip left>
                <template #activator="{ on }">
                    <v-tab class="rightBox__panel" href="#alerts" v-on="on">
                        <v-badge
                            v-if="alerts.length > 0"
                            class="badgeRB"
                            color="#ffcb00"
                            offset-x="-4"
                            offset-y="-16"
                            overlap
                        />
                        <v-icon color="white"> mdi-bell-ring </v-icon>
                    </v-tab>
                </template>
                <span>{{ $t('global:alerts') }}</span>
            </v-tooltip> -->

            <!-- <v-tooltip left>
                <template #activator="{ on }">
                    <v-tab
                        class="rightBox__panel"
                        href="#contacts"
                        @click="fetchContacts()"
                        v-on="on"
                    >
                        <v-icon color="white"> mdi-account-box </v-icon>
                    </v-tab>
                </template>
                <span>{{ $t('global:contacts') }}</span>
            </v-tooltip> -->

            <!-- <v-tooltip left>
                <template #activator="{ on }" @dragover.prevent @drop.prevent>
                    <v-tab
                        id="storageTab"
                        class="rightBox__panel"
                        href="#storage"
                        v-on="on"
                        @dragover.prevent
                        @drop.prevent
                        @drop="handleFileUpload"
                        @dragover="hoverStorage"
                        @dragleave="removeHoverEffect"
                    >
                        <v-icon color="white"> mdi-safe-square-outline </v-icon>
                    </v-tab>
                </template>
                <span>{{ $t('global:storage') }}</span>
            </v-tooltip> -->

            <v-tab-item class="rightBox__height" value="tasks">
                <div class="headline font-weight-light mb-2">
                    {{ $t('global:tasks').toUpperCase() }}
                </div>
                <!-- <transition name="fade" @enter="enter">
                    <Tasks v-if="model == 'tasks' && mini == false" />
                </transition> -->
            </v-tab-item>
            <v-tab-item class="rightBox__height" value="alerts">
                <div class="headline font-weight-light mb-2">
                    {{ $t('global:alerts').toUpperCase() }}
                </div>
                <transition name="fade" @enter="enter">
                    <!-- <Alerts
                        :items="getProfileDetails.alerts"
                        @showBadge="isNotSeenAlerts"
                    /> -->
                </transition>
            </v-tab-item>

            <v-tab-item class="rightBox__height" value="contacts">
                <div class="headline font-weight-light mb-2">
                    {{ $t('global:contacts').toUpperCase() }}
                </div>

                <transition name="fade" @enter="enter">
                    <!-- <Contacts :contacts="getContactsBoxTable.items" /> -->
                </transition>
            </v-tab-item>

            <v-tab-item class="rightBox__height" value="storage">
                <div class="headline font-weight-light mb-2">
                    {{ $t('global:storage').toUpperCase() }}
                </div>

                <transition name="fade" @enter="enter">
                    <!-- <Storage v-if="model == 'storage' && mini == false" /> -->
                </transition>
            </v-tab-item>
        </v-tabs>
        <!-- <v-btn class="rightBox__btnRight" icon @click.stop="mini = !mini">
            <v-icon v-if="mini" color="white"> mdi-chevron-left </v-icon>
            <v-icon v-else color="white"> mdi-chevron-right </v-icon>
        </v-btn> -->
    </v-navigation-drawer>
</template>

<script>
// import Tasks from './Tasks/RightBoxTasksCard'
import Alerts from './Alerts/RightBoxAlertsCard'
import Contacts from './Contacts/RightBoxContactsCard'
import Storage from './Storage/RightBoxStorageCard'
import { mapActions, mapGetters, mapMutations } from 'vuex'

export default {
    i18nOptions: { namespaces: ['global'] },
    data() {
        return {
            notificationToDelete: false,
            mini: true,
            alerts: [],
            tasks: [],
            overlap: true,
            show: false,
            model: 'tasks',
        }
    },
    computed: {
        ...mapGetters([
            'getProfileDetails',
            'getContactsBoxTable',
            'getStorageModal',
            // 'getTasksPersonal',
            'getProfileDetails',
        ]),
        contacts: {
            get() {
                return this.getContactsTable.items
            },
        },
    },
    components: {
        // Tasks,
        // Alerts,
        // Contacts,
        // Storage,
    },
    methods: {
        ...mapActions([
            'fetchContactsBoxTable',
            'createStorageFile',
            'fetchTasksCountPersonal',
            'fetchTasksTable',
        ]),
        ...mapMutations(['clearStorageFields']),
        fetchContacts() {
            this.fetchContactsBoxTable()
        },

        enter: function (el, done) {
            var that = this
            setTimeout(function () {
                that.mini = false
            }, 200)
        },
        isNotSeenAlerts() {
            // ZAKOMENTOWANE
            // this.alerts = this.getProfileDetails.alerts.filter(
            //     a => a.seen == false
            // )
        },

        handleFileUpload(e) {
            this.getStorageModal.attachmentsComputer = [
                ...e.dataTransfer.files,
                this.getStorageModal.attachmentsComputer,
            ].flat()
            this.createStorageFile()
            this.clearStorageFields()
            this.removeHoverEffect()
            this.clearStorageErrors()
        },
        hoverStorage() {
            document.querySelector('#storageTab').style.backgroundColor =
                'rgba(25, 170, 141, .5)'
        },
        removeHoverEffect() {
            document.querySelector('#storageTab').style.backgroundColor =
                'transparent'
        },
    },
    beforeMount() {
        this.isNotSeenAlerts()
    },
}
</script>
