import Vue from 'vue'
import Vuex from 'vuex'
import createLogger from 'vuex/dist/logger'
import central from './Global/central';
import translations from './Admin/translations';
import profile from './Personal/profile';
import service from './Registries/Service/service';
import adminSetup from './Registries/AdminSetup/adminSetup'

const debug = process.env.NODE_ENV !== 'production';
const plugins = debug
    ? [
          createLogger({
              filter(mutations) {
                  return (
                      mutations.type !== 'setTimer' &&
                      mutations.type !== 'decrementSessionTime' &&
                      mutations.type !== 'decrementSessionTime'
                  )
              },
          }),
      ]
    : []

Vue.use(Vuex)

export default new Vuex.Store({
  state: {
    dialog: false,
  },
  getters: {},
  mutations: {},
  actions: {},
  modules: {
    central,
    translations,
    profile,
    service,
    adminSetup
  },
  // strict: debug,
  // plugins: plugins,
});
