import i18n from './i18next';

const frontVal = (state, payload, registry) => {
  const arr = [];
  //rest fields
  for (const step in payload) {
    payload[step].forEach((element) => {
      if (
        !state.fields[element] ||
        state.fields[element] == '' ||
        state.fields[element] == undefined
      ) {
        state.errors[step].fields[element] = `${i18n.t('global:errorFields')}!`;
        state.errors[step].validation = false;
        arr.push(step);
      } else {
        state.errors[step].fields[element] = '';
        state.errors[step].validation = true;
      }
    });
  }
};

//   FUNCTION TO VALIDATE
const setErrors = (state, payload) => {
  const arr = [];
  if (payload.response.data.errors) {
    for (const step in state) {
      for (const key in state[step].fields) {
        if (
          payload.response.data.errors[key]?.message ===
          'ContractAnnexNo arleady exists'
        ) {
          state[step].fields[key] = `${i18n.t('global:errorContractNoUsed')}!`;
          arr.push(step);
        } else if (
          payload.response.data.errors[key]?.message ===
          'ExternalContractNumber arleady exists'
        ) {
          state[step].fields[key] = `${i18n.t('global:errorContractNoUsed')}!`;
          arr.push(step);
        } else if (
          payload.response.data.errors[key]?.message ===
          'ContractNumber arleady exists'
        ) {
          state[step].fields[key] = `${i18n.t('global:errorContractNoUsed')}!`;
          arr.push(step);
        } else if (
          payload.response.data.errors[key]?.message === 'Device arleady used'
        ) {
          state[step].fields[key] = `${i18n.t('global:errorDeviceUsed')}!`;
          arr.push(step);
        } else if (
          payload.response.data.errors[key]?.message === 'Invalid email'
        ) {
          state[step].fields[key] = `${i18n.t('global:errorEmailValidation')}!`;
          arr.push(step);
        } else if (payload.response.data.errors[key]) {
          state[step].fields[key] = `${i18n.t('global:errorFields')}!`;
          arr.push(step);
        } else {
          state[step].fields[key] = '';
          state[step].validation = true;
        }
      }
    }
  }
  arr.forEach((element) => {
    state[element].validation = false;
  });
  if (payload.response.data.keyValue) {
    state.stepOne.url = `${i18n.t('global:urlExist')}!`;
  }
  if (payload.response.data.errors?.sn === 'serial number must be unique') {
    state.stepOne.fields.sn = `${i18n.t('global:snExist')}!`;
  }
  if (payload.response.data.errors?.to) {
    state.stepTwo.to = `${i18n.t('global:errorFields')}!`;
  }
};

export default { frontVal, setErrors };
