<template>
  <v-container class="central pa-1">
    <v-row>
      <v-col
        cols="12"
        class="px-2"
      >
        <v-text-field
          ref="search"
          v-model="search"
          hide-details
          single-line
          :label="$t('global:search')"
          @input="$emit('search', search)"
        >
          <template #prepend>
            <v-icon
              color="secondary"
              @click="setFocus()"
            >
              mdi-magnify
            </v-icon>
          </template>
        </v-text-field>
      </v-col>
    </v-row>
    <v-row>
      <v-col
        cols="12"
        class="pa-0"
      >
        <v-data-table
          :items="items"
          height="'calc(100vh - 362px)' "
          fixed-header
          :search="search"
          :items-per-page="11"
          class="central__table mt-4 pa-0"
          hide-default-footer
        >
          <template #body>
            <!-- @slot Use this slot to place the table records -->
            <slot name="columns" />
          </template>
        </v-data-table>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
/**
 * Central Table
 * @displayName Central Table
 */
export default {
    i18nOptions: { namespaces: ['global'] },
    props: {
        items: {
            /**
             * Items in Table
             */
            type: Array,
            required: false,
        },
        /**
         * Headers in Table
         */
        headers: {
            type: Array,
            required: true,
        },
    },

    data() {
        return {
            search: '',
        }
    },
    methods: {
        setFocus() {
            this.$refs.search.focus()
        },
    },
}
</script>
<style lang="scss">
.central__table {
    .v-data-table__progress {
        [role='progressbar'] {
            .v-progress-linear__indeterminate {
                background-color: #19aa8d !important;
            }
        }
    }
}
</style>
