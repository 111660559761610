<template>
  <!--
    Close modala 
    @event close 
    -->
  <v-dialog
    v-model="isOpen"
    :width="width"
    :fullscreen="fullscreen"
  >
    <v-card class="AE">
      <div
        class="
                    headline
                    grey
                    lighten-2
                    AE__header
                    font-weight-light
                    d-flex
                    align-center
                    justify-center
                "
      >
        <span class="font-weight-light">{{ title }}</span>
        <v-btn
          class="AE__header__close"
          icon
          x-small
          @click="$emit('close')"
        >
          <v-icon color="grey">
            mdi-close
          </v-icon>
        </v-btn>
      </div>
      <div
        class="AE__content"
        :style="`height:${height}px `"
      >
        <!-- @slot Use this slot to add modal content-->
        <slot name="AEContent" />
      </div>
      <div class="AE__footer">
        <v-btn
          class="mx-3 buttons"
          @click="$emit('close')"
        >
          {{
            $t('global:cancel')
          }}
        </v-btn>
        <slot name="buttons" />
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
/**
 * NewServiceModal
 * @displayName NewServiceModal
 */
import Vue from 'vue'
export default {
    i18nOptions: { namespaces: ['global'] },
    props: {
        /**
         * Title in NewServiceModal
         */
        title: {
            type: String,
            default: 'Edit',
            required: true,
        },
        /**
         * Width modal
         */
        width: {
            type: String,
            default: '800px',
        },
        height: {
            type: String,
            default: '600px',
        },
        open: {
            type: Boolean,
            default: false,
        },
        fullscreen: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        isOpen: {
            get() {
                return this.open
            },
            set(value) {
                this.$emit('close')
            },
        },
    },
}
</script>
<style lang="scss">
.v-dialog {
    &--fullscreen {
        max-width: 100vw !important;
        max-height: 100vh !important;

        .AE__content {
            height: 90vh;
        }
    }
}

@media only screen and (max-width: 1440px) {
    .v-dialog {
        &--fullscreen {
            max-width: 100vw !important;
            max-height: 90vh !important;

            .AE__content {
                height: 80vh;
            }
        }
    }
}
</style>
