<template>
  <v-list>
    <v-list-item
      v-for="item in getStorageTable.items"
      :key="item._id"
      :href="setUrl(item.fakeName)"
      target="_blank"
    >
      <v-tooltip bottom>
        <template #activator="{ on }">
          <v-list-item-content v-on="on">
            <v-list-item-title>{{ item.name }}</v-list-item-title>
            <v-list-item-subtitle>
              {{
                $moment(item.createdAt).format(`DD-MM-YYYY HH:mm`)
              }}
            </v-list-item-subtitle>
          </v-list-item-content>
        </template>
        <span>{{ $t('attachments:download') }}</span>
      </v-tooltip>
    </v-list-item>
  </v-list>
</template>

<script>
import store from './../../../../store/index'
import { mapGetters, mapMutations, mapActions } from 'vuex'

export default {
    props: [],
    i18nOptions: { namespaces: ['global'] },
    data: () => ({}),

    computed: {
        ...mapGetters(['getStorageTable']),
    },

    methods: {
        ...mapActions(['fetchStorage']),
        setUrl(fakeName) {
            return `${process.env.VUE_APP_SERVER}/secured/storage/${fakeName}`
        },
    },

    beforeMount() {
        this.fetchStorage({ limit: 20 })
    },
}
</script>
