var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"rightBox__cards"},_vm._l((_vm.items),function(item,index){return _c('v-alert',{key:index,staticClass:"rightBox__card py-2",attrs:{"border":"left","colored-border":"","color":_vm.$get(item, 'priority.backgroundColor', 'grey'),"elevation":"2","width":"310"}},[_c('p',{staticClass:"mt-2 mb-1 rightBox__card__description"},[_vm._v(" "+_vm._s(item.description)+" ")]),_c('span',{staticClass:"rightBox__card__line"}),_c('span',{staticClass:"rightBox__card__date"},[_c('b',[_vm._v(_vm._s(_vm.$t('global:term'))+":")]),_vm._v(" "+_vm._s(item.alertTime)+" "+_vm._s(_vm.$moment(item.alertDate).format("DD-MM-YYYY")))]),_c('div',[_c('span',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mr-1",attrs:{"size":"15","color":"primary"}},on),[_vm._v("mdi-account")]),_c('span',_vm._g({staticClass:"cursor-pointer rightBox__card__summary"},on),[_vm._v(_vm._s(_vm.$get(item, 'from.name', ''))+" "+_vm._s(_vm.$get(item, 'from.lastname', '')))])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('tasks:created')))])])],1),_c('span',{staticClass:"rightBox__card__span"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-icon',_vm._g({staticClass:"mr-1",attrs:{"size":"15","color":"primary"}},on),[_vm._v("mdi-calendar-month")]),_c('span',_vm._g({staticClass:"cursor-pointer rightBox__card__summary"},on),[_vm._v(_vm._s(_vm.$moment(item.createdAt).format("HH:mm"))+" "+_vm._s(_vm.$moment(item.createdAt).format("DD-MM-YYYY")))])]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('tasks:createdDate')))])])],1)]),(!item.seen)?_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [(!item.seen)?_c('v-avatar',_vm._g({staticClass:"rightBox__card__new",attrs:{"color":"#ffcb00","size":"28"},on:{"click":function($event){return _vm.setSeen(item)}}},on),[_c('span',{staticClass:"white--text"},[_vm._v("N")])]):_vm._e()]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('alerts:unread')))])]):_vm._e(),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"rightBox__card__arrow",attrs:{"small":"","to":'/alerts',"icon":""}},on),[_c('v-icon',{attrs:{"size":"18","color":"white"}},[_vm._v(" mdi-arrow-right-thick ")])],1)]}}],null,true)},[_c('span',[_vm._v(_vm._s(_vm.$t('alerts:go')))])])],1)}),1)}
var staticRenderFns = []

export { render, staticRenderFns }