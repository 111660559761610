<template>
  <v-container class="d-flex justify-space-between px-2 py-0 mt-0">
    <h1
      class="font-weight-light cursor-pointer mt-1"
      @click="$emit('clearSearch'), clearSearchText()"
    >
      {{ title || '' }}
    </h1>
    <!-- ZAKOMENTOWANE -->
    <!-- <Avatar
            v-if="avatarData"
            :name="avatarData.name"
            :lastname="avatarData.lastname"
            :tooltip="$t('global:moduleManager')"
            :phone="avatarData.phone || ''"
            :email="avatarData.mail || ''"
            :picture="avatarData.picture || ''"
        /> -->
  </v-container>
</template>
<script>
import Avatar from '../Avatar/TheAvatar'
import { mapGetters } from 'vuex'
/**
 * Central Table
 * @displayName  Overlay Header
 */
export default {
    components: {
        // <!-- ZAKOMENTOWANE -->
        // Avatar,
    },
    props: {
        /**
         * Main view / Title
         */
        title: {
            type: String,
            required: true,
        },
        // <!-- ZAKOMENTOWANE -->
        // avatarData: {
        //     type: Object,
        //     default: null,
        // },
    },
    computed: {
        ...mapGetters(['getSearch']),
    },
    methods: {
        clearSearchText() {
            this.getSearch.text = ''
        },
    },
}
</script>
<style lang="scss" scoped>
@media only screen and (max-width: 1440px) {
    .vertical-line {
        margin-left: 5px;
        margin-top: 7px;
        height: 18px;
        border-left: 1px solid #111;
    }
}
</style>
