import Vue from 'vue'
import Router from 'vue-router'
import Overlay from './views/Global/TheViewOverlay'
import Rest from './plugins/Rest'
import store from './store/index'
// import i18next from 'i18next';

Vue.use(Router)

const router = new Router({
    routes: [
        {
            path: '/',
            redirect: 'dashboard',
            meta: {
                requiresAuth: false,
            },
            component: Overlay,
            children: [
                {
                    path: 'dashboard',
                    name: 'dashboard',
                    meta: {
                        requiresAuth: true,
                    },
                    component: () =>
                        import('./views/Global/TheViewDashboard.vue'),
                },
                // === PROFILE ===
                {
                    path: 'profile',
                    name: 'profile',
                    meta: {
                        requiresAuth: true,
                    },
                    component: () =>
                        import(
                            './views/Personal/Profile/ViewsProfileCentral.vue'
                        ),
                },
                {
                    path: 'service-orders',
                    name: 'service-orders',
                    meta: {
                        requiresAuth: true,
                    },
                    component: () =>
                        import(
                            './views/Registries/ServiceOrders/ViewsServiceOrders.vue'
                        ),
                },
                {
                    path: 'admin-setup',
                    name: 'admin-setup',
                    meta: {
                        requiresAuth: true,
                    },
                    component: () =>
                        import(
                            './views/Registries/AdminSetup/ViewsAdminSetup.vue'
                        ),
                },
                {
                    path: 'adminSetup/:id',
                    name: 'admin-setup-id',
                    component: () =>
                        import('./views/Registries/AdminSetup/Details.vue'),
                },
            ],
        },
        // === SERVICE--PRINT ===

        {
            path: '/login',
            name: 'Login',

            meta: {
                requiresAuth: false,
            },
            component: () => import('./views/Global/TheViewLogin.vue'),
        },
        {
            path: '/404',
            name: '404',
            meta: {
                requiresAuth: true,
            },
            component: () => import('./views/Global/TheView404.vue'),
        },
        {
            path: '/403',
            name: '403',
            meta: {
                requiresAuth: true,
            },
            component: () => import('./views/Global/TheView403.vue'),
        },
    ],
})

const checkLoad = next => {
    if (store.getters.getLanguageState) next()
}
//waiting for translations
router.beforeEach((to, from, next) => {
    checkLoad(next)
    store.watch(
        state => state.translations.loadLang,
        () => {
            checkLoad(next)
        }
    )
})

router.beforeEach(async (to, from, next) => {
    try {
        if (!to?.meta?.requiresAuth) {
            return next()
        }
        const token = sessionStorage.getItem('Token')
        if (to.path === '/login') return next()
        if (from.path === '/' && to.path !== '/dashboard') {
            if (to.path !== '/login' && !token) {
                return next('/login')
            } else {
                const res = await new Rest('GET', 'token/getUser').send()
                if (!res.data) {
                    if (to.path !== '/login') {
                        return next('/login')
                    }
                }
                store.commit('setUserSession', {
                    time: res.data.session.time,
                    id: res.data.session.id,
                })
                store.commit('setPicture', res?.data?.pictureBinary?.data)
                store.commit('setUserData', res?.data)
                return next('/dashboard')
            }
        }

        if (to.path !== '/login' && !token) {
            return next('/login')
        } else {
            const res = await new Rest('GET', 'token/getUser').send()
            if (!res.data) {
                if (to.path !== '/login') {
                    return next('/login')
                }
            }
            store.commit('setUserSession', {
                time: res.data.session.time,
                id: res.data.session.id,
            })
            store.commit('setPicture', res?.data?.pictureBinary?.data)
            store.commit('setUserData', res?.data)
            return next()
        }
    } catch (err) {
        console.error(err)
        if (to.path !== '/login') {
            next('/login')
        }
    }
})

export default router
