import Rest from './../../../plugins/Rest'
import clone from 'lodash.clone'
import clearSearch from './../../../plugins/clearSearch'
const state = {
    modal: {
        fields: {
            name: '',
            lastName: '',
            login: '',
            password: null,
            passwordAgain: null,
            email: '',
            comments: '',
            isAdmin: false,
            isActive: false,
            isBanned: false,
        },
        errors: {
            stepOne: {
                validation: true,
                fields: {
                    name: '',
                    lastName: '',
                    contact: '',
                    login: '',
                    password: '',
                    passwordAgain: '',
                    phoneNumber: '',
                },
            },
        },
        steps: 1,
    },
    // TODO DEFAULT SEARCH FEILDS AND SEARCH FIELDS
    request: {
        searchFields: ['name join lastName', 'login'],
        defaultSearchFields: ['name join lastName', 'login'],
        populate: [],
        select: '_id name lastName createdAt isAdmin isActive login phoneNumber status comments',
        where: {
            isDeleted: false,
        },
    },
    table: {
        items: [],
        total: '',
        search: '',
        page: 1,
        limit: 9,
        sort: '',
        pages: 1,
        defaultSort: 'name',
        loading: false,
    },
    statuses: [],
    details: {},
    registry: {},
    dictFields: {},
    filterCounter: {},
    prevIntervention: {},
}

const getters = {
    getAdminSetupTable: state => state.table,
    getAdminSetupSearchFields: state => state.request,
    getAdminSetupStatuses: state => state.statuses,
    getAdminSetupDetails: state => state.details,
    getAdminSetupModal: state => state.modal.fields,
    getAdminSetupErrors: state => state.modal.errors,
}

const mutations = {
    setAdminSetupTable(state, payload) {
        state.table.items = payload.items || state.table.items

        state.table.total = payload.total || state.table.total
        if (payload.search != undefined) state.table.search = payload.search
        state.table.page = payload.page || state.table.page
        state.table.limit = payload.limit || state.table.limit
        state.table.pages = Math.ceil(state.table.total / state.table.limit)
        if (payload.sort != undefined) state.table.sort = payload.sort
    },
    setAdminSetupSearch(state, payload) {
        state.request.searchFields = payload
    },
    clearAdminSetupTable(state, payload) {
        clearSearch.clearSearchTable(state)
    },
    setAdminSetupDetails(state, payload) {
        state.details = payload
    },
    setAdminSetupStatuses(state, payload) {
        state.statuses = payload
    },
    setAdminSetupModal(state, payload) {
        let obj = payload ? payload : clone(state.details)

        state.modal.fields = obj
    },
    setAdminSetupStatus(state, payload) {
        state.modal.fields.status = payload
    },
}

const actions = {
    async fetchAdminSetupRegistry({ commit, dispatch }, payload) {
        let registry = await new Rest('GET', 'registries')
            .select('currentMenager name availableStatuses icon')
            .where({ url: 'service' })
            .populate([
                {
                    path: 'currentMenager',
                    select: 'name lastname mail phone picture',
                },
            ])
            .send()

        commit('setServiceStatuses', registry.data.items[0].availableStatuses)
        commit('setServiceRegistry', registry.data.items)
    },

    async fetchAdminSetupTable(
        { dispatch, commit, getters, rootGetters },
        payload
    ) {
        state.table.loading = true
        let sort, where

        try {
            let response = await new Rest('GET', 'adminSetup')
                .select(state.request.select)
                .limit(state.table.limit)
                .page(state.table.page)
                .search(state.request.searchFields, state.table.search)
                .where(where || state.request.where)
                .populate(state.request.populate)
                .sort(state.table.sort || sort || state.table.defaultSort)
                .send()

            commit('setAdminSetupTable', response.data)

            // await dispatch('countServiceFilters')
            // await dispatch('fetchServiceRegistry')
        } catch (err) {
            console.error(err)
        }
        state.table.loading = false
    },

    async fetchAdminSetupUser({ commit, dispatch }, payload) {
        try {
            let data = (
                await new Rest('GET', `adminSetup/${payload.id}`)
                    .select(state.request.select)
                    .populate(state.request.populate)
                    .send()
            ).data

            const statuses = data?.statuses?.availableStatuses

            commit('setAdminSetupDetails', data.user)

            commit('setAdminSetupStatuses', statuses)
            console.log(statuses)

            if (payload.next) payload.next()
        } catch (err) {
            console.error(err)
        }
    },

    async updateAdminSetupUser({ commit, dispatch }, payload) {
        try {
            await new Rest('PUT', `adminSetup/${payload.id}`)
                .setBody(state.modal.fields)
                .select(state.request.select)
                .populate(state.request.populate)
                .send()
            dispatch('fetchAdminSetupUser', { id: payload.id })
            // commit('clearNotifyUsersModal')
            // commit('clearNotifyUsersErrors')
            return true
        } catch (err) {
            //HANDLE ERRORS
            // validation.setErrors(state.modal.errors, err)
            commit('setSnackbars', { type: 'error' })
        }
    },

    async updateAdminSetupStatus({ commit, dispatch }, payload) {
        commit('setAdminSetupModal')
        commit('setAdminSetupStatus', payload.status)
        try {
            let response = await new Rest('PUT', `adminSetup/${payload.id}`)
                .setBody(state.modal.fields)
                .select(state.request.select)
                .populate(state.request.populate)
                .send()
            commit('setSnackbars', { type: 'success' })
        } catch (err) {
            console.error(err)
            commit('setSnackbars', { type: 'error' })
        }
    },
}

export default {
    state,
    getters,
    actions,
    mutations,
}
